import axios from "@/utils/axios";

import apiPath from "@/utils/apiPath";
const api = apiPath.psm;
const inspection = apiPath.inspection;

// 查询检测记录列表（分页）
export const getEquipmentCheckRecord = params => {
  return axios.get(`${inspection}/page/detection`, { params });
};
