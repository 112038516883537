<template>
  <div class="equipment-wrapper">
    <van-nav-bar
      title="设备信息一览"
      :border="false"
      left-arrow
      @click-left="$router.back()"
    >
    </van-nav-bar>
    <div class="equipment-info">
      <span class="report-fault" @click="onAddFault()">报故障</span>
      <div class="equipment-desc">
        <div
          class="equipment-desc-box"
          :class="{
            key: info.level == 2,
            core: info.level == 3,
            scrap: info.scrap
          }"
        >
          <span v-if="info.scrap" class="scrap-mark">报废</span>
          <span class="equipment-type">{{ info.typeName }}</span>
          <div class="equipment-name">
            {{ info.name }}
            <span class="equipment-no">{{ info.machineNo }}</span>
          </div>
          <div class="equipment-category">{{ info.levelName }}</div>
          <div class="eqipment-type2">{{ info.categoryName }}</div>
          <span class="equipment-detail-btn" @click="onToInfo()"
            >查看详情<i class="iconfont iconfont-jiantou"></i
          ></span>
        </div>
      </div>
      <div class="equipment-modules">
        <span
          v-for="item in modulesCom"
          :key="item.name"
          class="equipment-module"
          @click="onModuleClick(item)"
        >
          <span class="equipment-module-icon">
            <svg class="icon svg-icon" aria-hidden="true">
              <use :xlink:href="item.icon"></use>
            </svg>
          </span>
          <span class="equipment-module-name">{{ item.name }}</span>
        </span>
      </div>
      <div class="equipment-calender">
        <equipment-calender :id="id" @change="onCalenderChange" />
      </div>
      <div class="equipment-tabs">
        <van-tabs v-model="activeName" @click="onTabChange">
          <van-tab title="检修记录" name="a">
            <div class="equipment-list">
              <van-empty
                v-if="tabDataObj.a.length == 0"
                :image="emptyImage"
                description="暂无数据"
              />
              <div
                v-for="item in tabDataObj.a"
                :key="item.id"
                class="equipment-item"
              >
                <div class="item-project">{{ item.project }}</div>
                <span class="item-mode">{{ item.modeName }}</span>
                <van-divider />
              </div>
            </div>
          </van-tab>
          <van-tab title="润滑保养记录" name="b">
            <div class="equipment-list">
              <van-empty
                v-if="tabDataObj.b.length == 0"
                :image="emptyImage"
                description="暂无数据"
              />
              <div
                v-for="item in tabDataObj.b"
                :key="item.id"
                class="equipment-item"
              >
                <div class="item-project">{{ item.project }}</div>
                <span class="item-mode">{{ item.typeName }}</span>
                <van-divider />
              </div>
            </div>
          </van-tab>
          <van-tab title="巡检记录" name="c">
            <div class="equipment-list">
              <van-empty
                v-if="tabDataObj.c.length == 0"
                :image="emptyImage"
                description="暂无数据"
              />
              <div
                v-for="item in tabDataObj.c"
                :key="item.id"
                class="equipment-item"
              >
                <div class="item-project ">{{ item.name }}</div>
                <span class="item-mode">{{ item.taskTypeName }}</span>
                <van-divider />
              </div>
            </div>
          </van-tab>
          <van-tab title="点检记录" name="f">
            <div class="equipment-list">
              <van-empty
                v-if="tabDataObj.f.length == 0"
                :image="emptyImage"
                description="暂无数据"
              />
              <div
                v-for="item in tabDataObj.f"
                :key="item.id"
                class="equipment-item"
              >
                <div class="item-project">{{ item.name }}</div>
                <span class="item-mode">{{ item.taskTypeName }}</span>
                <div class="check-item">
                  <div class="check-item-header">
                    <div>检测项名称</div>
                    <div class="tc">测量结果</div>
                    <div class="tr">检查结果</div>
                  </div>
                  <div
                    v-for="(objectItem, idx) in item.checkResults"
                    :key="idx"
                    class="check-item-object"
                  >
                    <div>{{ objectItem.name }}</div>
                    <div class="tc">{{ objectItem.actualResult || "-" }}</div>
                    <div
                      class="tr"
                      :class="objectItem.verdict === 0 ? 'c-red' : 'c-green'"
                    >
                      {{ objectItem.verdict === 0 ? "异常" : "正常" }}
                    </div>
                  </div>
                </div>
                <van-divider />
              </div>
            </div>
          </van-tab>
          <van-tab title="检定记录" name="d">
            <div class="equipment-list">
              <van-empty
                v-if="tabDataObj.d.length == 0"
                :image="emptyImage"
                description="暂无数据"
              />
              <div
                v-for="item in tabDataObj.d"
                :key="item.id"
                class="equipment-item"
              >
                <div class="item-project">{{ item.verifyItem }}</div>
                <!-- <span class="item-mode">{{ item.typeName }}</span> -->
                <van-divider />
              </div>
            </div>
          </van-tab>
          <van-tab title="检测记录" name="e">
            <div class="equipment-list">
              <van-empty
                v-if="tabDataObj.e.length == 0"
                :image="emptyImage"
                description="暂无数据"
              />
              <div
                v-for="item in tabDataObj.e"
                :key="item.id"
                class="equipment-item check"
              >
                <div class="title-box">
                  <span class="title">{{ item.name }}</span>
                  <span class="item-mode">{{ item.typeName }}</span>
                </div>
                <div class="check-num-box">
                  <div class="item">
                    <p class="val">{{ item.range }}</p>
                    <p class="label">合格范围</p>
                  </div>
                  <div class="item">
                    <p class="val">{{ item.val }}</p>
                    <p class="label">检测结果</p>
                  </div>
                  <div class="item">
                    <p class="val val-res">
                      {{ item.val > item.range ? "正常" : "异常" }}
                    </p>
                    <p class="label">检测结果</p>
                  </div>
                </div>
                <van-divider />
              </div>
            </div>
          </van-tab>
          <van-tab name="h">
            <!-- 保持滚动 -->
          </van-tab>
        </van-tabs>
      </div>
    </div>
  </div>
</template>

<script>
import equipmentCalender from "./equipmentCalender.vue";
import {
  getEquipmentInfo,
  getEquipmentOverhaulRecord,
  getEquipmentMaintainverhaulRecord,
  getEquipmentPatrolRecord,
  getEquipmentVerifyRecord,
  getPointCheckRecord,
  getPointCheckRecordObject
} from "@/api/basics/equipment";
import { getEquipmentCheckRecord } from "@/api/psm/equipment";
const d = new Date();
const year = d.getFullYear();
const month = `00${d.getMonth() + 1}`.slice(-2);
const day = `00${d.getDate()}`.slice(-2);
import equipmentMixin from "./equipmentMixin";
export default {
  mixins: [equipmentMixin],
  components: {
    equipmentCalender
  },
  props: {
    id: {
      type: String,
      default: ""
    }
  },
  data() {
    return {
      activeName: "a",
      info: {},
      curDayObj: {
        date2: `${year}-${month}-${day}`,
        datetime: `${year}-${month}-${day} 00:00:00`,
        nextdatetime: `${year}-${month}-${day} 23:59:59`
      },
      modules: [
        {
          icon: "#iconfont-jianxiujihua",
          name: "检修计划",
          routerName: "equipmentOverhaul"
        },
        {
          icon: "#iconfont-baoyangjihua1",
          name: "保养计划",
          routerName: "equipmentledgerMaintain"
        },
        {
          icon: "#iconfont-jiandingjihua1",
          name: "检定计划",
          routerName: "equipmentVerification"
        },
        {
          icon: "#iconfont-guanlianfujian1",
          name: "关联辅件",
          routerName: "equipmentSubsidiary"
        },
        {
          icon: "#iconfont-guzhangjilu",
          name: "故障记录",
          routerName: "equipmentFault"
        },
        {
          icon: "#iconfont-gongdanguanli",
          name: "工单管理",
          routerName: "equipmentWorkOrder"
        },
        {
          icon: "#iconfont-shebeiwendang",
          name: "设备文档",
          routerName: "equipmentDocument"
        },
        {
          icon: "#iconfont-anquanfujian",
          name: "安全附件",
          routerName: "equipmentAffix",
          hide: true
        }
      ],
      tabDataObj: {
        a: [],
        b: [],
        c: [],
        d: [],
        e: [],
        f: []
      }
    };
  },
  computed: {
    modulesCom() {
      return this.modules.filter(a => !a.hide);
    }
  },
  created() {
    this.getEquipmentInfo();
    this.getData(this.activeName);
  },
  methods: {
    onAddFault() {
      this.$router.push({
        name: "equipmentFaultAdd",
        params: {
          id: this.info.id,
          info: this.info
        }
      });
    },
    onModuleClick(item) {
      console.log("onModuleClick", item);
      this.$router.push({
        name: item.routerName,
        params: {
          id: this.id
        }
      });
    },
    // 修改日期
    onCalenderChange(v) {
      this.curDayObj = v;
      this.tabDataObj = {
        a: [],
        b: [],
        c: [],
        d: [],
        e: [],
        f: []
      };
      this.getData(this.activeName);
    },
    // tab切换
    onTabChange(name, title) {
      console.log("name", name, title);
      if (this.tabDataObj[name] && this.tabDataObj[name].length) {
        return;
      }
      // 获取数据
      this.getData(name);
    },
    getData(tabName) {
      const obj = {
        a: "getEquipmentOverhaulRecord",
        b: "getEquipmentMaintainverhaulRecord",
        c: "getEquipmentPatrolRecord",
        d: "getEquipmentVerifyRecord",
        e: "getEquipmentCheckRecord",
        f: "getPointCheckRecord"
      };
      this[obj[tabName]]();
    },
    // 设备详情
    getEquipmentInfo() {
      getEquipmentInfo(this.id).then(res => {
        this.info = res;
        // 如果是特种设备
        if (res.categoryName && res.categoryName.includes("特种设备")) {
          this.modules[this.modules.length - 1].hide = false;
        }
      });
    },
    // 检修记录
    getEquipmentOverhaulRecord() {
      getEquipmentOverhaulRecord(this.id, {
        actualStart: this.curDayObj.date2,
        actualEnd: this.curDayObj.date2
      }).then(res => {
        this.tabDataObj.a = res.list;
      });
    },
    // 润滑保养记录
    getEquipmentMaintainverhaulRecord() {
      getEquipmentMaintainverhaulRecord(this.id, {
        actualStart: this.curDayObj.date2,
        actualEnd: this.curDayObj.date2
      }).then(res => {
        this.tabDataObj.b = res.list;
      });
    },
    // 巡检记录
    getEquipmentPatrolRecord() {
      getEquipmentPatrolRecord(this.id, {
        validStartTime: this.curDayObj.datetime,
        validEndTime: this.curDayObj.nextdatetime
      }).then(res => {
        this.tabDataObj.c = res.list;
      });
    },
    // 检定记录
    getEquipmentVerifyRecord() {
      getEquipmentVerifyRecord(this.id, {
        actualStart: this.curDayObj.date2,
        actualEnd: this.curDayObj.date2
      }).then(res => {
        this.tabDataObj.d = res.list;
      });
    },
    // 检测记录
    getEquipmentCheckRecord() {
      const params = {
        equipmentRecordId: this.id,
        actualDate: this.curDayObj.date2,
        page: 1,
        size: 9999
      };
      getEquipmentCheckRecord(params).then(res => {
        this.tabDataObj.e = res.list;
      });
    },
    // 点检记录
    getPointCheckRecord() {
      this.tabDataObj.f = [];
      const params = {
        equipmentId: this.id,
        actualStartTime: this.curDayObj.date2 + " 00:00:00",
        actualEndTime: this.curDayObj.date2 + " 23:59:59",
        typeFlag: 2,
        pointCheckFlag: 2
      };
      getPointCheckRecord(params).then(res => {
        this.tabDataObj.f = res;
      });
    },

    // 跳转到基本信息
    onToInfo() {
      this.$router.push({
        name: "equipmentledgerInfo",
        params: { id: this.id }
      });
    }
  },
  mounted() {}
};
</script>

<style lang="scss" scoped>
.equipment-wrapper {
  height: 100%;
  display: flex;
  background-color: #eceef2;
  flex-direction: column;
  .equipment-info {
    position: relative;
    flex: 1;
    overflow-y: auto;
    .report-fault {
      position: fixed;
      width: 80px;
      height: 80px;
      text-align: center;
      line-height: 80px;
      bottom: 10px;
      left: 50%;
      transform: translateX(-50%);
      background-color: rgb(26, 99, 251);
      color: #fff;
      font-weight: 700;
      border-radius: 50%;
      font-size: 18px;
      z-index: 90;
      box-shadow: 0 5px 20px 0px #ccc;
    }
    // 设备基本信息
    .equipment-desc {
      padding: 12px;
      background-color: #fff;
      &-box {
        height: 128px;
        border-radius: 12px;
        background: url("../../assets/images/equipment/bg-normal.svg") no-repeat
          center center;
        background-size: cover;
        position: relative;
        padding: 20px 25px;
        box-sizing: border-box;
        position: relative;
        .equipment-detail-btn {
          position: absolute;
          right: 12px;
          bottom: 16px;
          height: 28px;
          line-height: 28px;
          width: 97px;
          border-radius: 14px;
          background-color: #056cff;
          color: #fff;
          font-size: 12px;
          text-align: center;
          .iconfont {
            font-size: 12px;
            margin-left: 6px;
          }
        }
        .equipment-type {
          position: absolute;
          top: 21px;
          right: 0;
          height: 21px;
          line-height: 21px;
          border-radius: 11px 0 0 11px;
          padding: 0 12px;
          background-color: #1676ff;
          font-size: 12px;
          color: #cfe2fd;
          font-weight: 700;
        }
        .equipment-name {
          color: #fff;
          font-weight: 700;
          font-size: 18px;
          .equipment-no {
            font-size: 14px;
          }
        }
        .equipment-category {
          margin-top: 10px;
          height: 18px;
          line-height: 18px;
          font-size: 12px;
          color: #4e4e4e;
          background: url("../../assets/images/equipment/type-normal.svg")
            no-repeat left center;
          padding-left: 20px;
        }
        .eqipment-type2 {
          margin-top: 26px;
          font-size: 12px;
          color: rgb(203, 223, 254);
        }
        .scrap-mark {
          position: absolute;
          top: 50px;
          left: 140px;
          transform: rotate(-28deg);
          font-size: 44px;
          opacity: 0.46;
          color: #fff;
        }
        &.scrap {
          background-image: url("../../assets/images/equipment/bg-scrap.svg");
          background-size: cover;
          .equipment-name {
            color: #888784;
          }
          .equipment-category {
            color: #4e4e4e;
            background: url("../../assets/images/equipment/type-scrap.svg")
              no-repeat left center;
          }
          .equipment-detail-btn {
            background-color: #cecdcc;
            color: #888784;
          }
          .equipment-type {
            background-color: rgba(255, 255, 255, 0.3);
            color: #888784;
          }
          .eqipment-type2 {
            color: #888784;
          }
        }
        &.key {
          background-image: url("../../assets/images/equipment/bg-key.svg");
          background-size: cover;
          .equipment-category {
            background: url("../../assets/images/equipment/type-key.svg")
              no-repeat left center;
          }
          .equipment-detail-btn {
            background-color: #afb1bf;
            color: #fff;
            box-shadow: 0px 1px 14px 0px #d8d8d8;
          }
          .equipment-type {
            background-color: #d8d9df;
            color: #292627;
          }
          .equipment-name {
            color: #32312e;
            .equipment-no {
            }
          }
          .eqipment-type2 {
            color: rgb(70, 70, 70);
          }
        }
        &.core {
          background: url("../../assets/images/equipment/bg-core.svg") no-repeat
            center center;
          background-size: cover;
          .equipment-category {
            background: url("../../assets/images/equipment/type-core.svg")
              no-repeat left center;
          }
          .equipment-detail-btn {
            background-color: #292627;
            color: #fdd7b5;
            box-shadow: 0px 2px 16px 0px rgba(229, 189, 152, 0.46),
              0px 2px 4px 0px rgba(112, 78, 49, 0.56);
          }
          .equipment-type {
            background-color: rgba(255, 255, 255, 0.3);
            color: #292627;
          }
          .equipment-name {
            color: #32312e;
            .equipment-no {
            }
          }
          .eqipment-type2 {
            color: #32312e;
          }
        }
      }
    }
    // 设备模块
    .equipment-modules {
      margin-bottom: 10px;
      background-color: #fff;
      .equipment-module {
        height: 55px;
        width: 25%;
        margin-bottom: 12px;
        display: inline-flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        .equipment-module-icon {
          flex: 1;
          display: flex;
          align-items: center;
          .iconfont {
            display: inline-block;
            height: 24px;
            height: 24px;
            font-size: 24px;
          }
          .icon {
            width: 24px;
            height: 24px;
            font-size: 30px;
            &.svg-icon {
              fill: currentColor;
            }
          }
        }
        .equipment-module-name {
          font-size: 14px;
          color: #222;
          margin-top: 9px;
          font-weight: 500;
        }
      }
    }
    // 日历
    .equipment-calender {
      background-color: #fff;
      padding: 16px;
    }
    .equipment-tabs {
      background-color: #fff;
      ::v-deep {
        .van-tab__pane {
          min-height: 400px;
          padding-top: 14px;
        }
      }
      .equipment-item {
        position: relative;
        padding: 0 20px 0;
        .item-project {
          font-size: 16px;
          color: #141313;
          font-weight: 600;
          width: 280px;
          overflow: hidden;
          white-space: nowrap;
          text-overflow: ellipsis;
        }
        .item-mode {
          position: absolute;
          right: 16px;
          top: 0;
          font-size: 12px;
          padding: 4px 8px;
          background-color: rgba(254, 119, 11, 0.1);
          border-radius: 14px;
          color: #fe770b;
        }
        .check-item {
          margin-bottom: 4px;
          .check-item-object {
            margin-top: 8px;
            font-weight: 600;
            overflow: hidden;
            > div {
              float: left;
              width: 33.3%;
              overflow: hidden;
              white-space: nowrap;
              text-overflow: ellipsis;
            }
          }
          .check-item-header {
            margin-top: 16px;
            font-size: 14px;
            overflow: hidden;
            > div {
              float: left;
              width: 33.3%;
            }
          }
          .c-green {
            color: #57c443;
          }
          .c-red {
            color: #f56c6c;
          }
          .tc {
            text-align: center;
          }
          .tr {
            text-align: right;
          }
        }
      }
      .check {
        .title-box {
          display: flex;
          justify-content: space-between;
          .title {
            font-size: 16px;
            font-weight: 600;
            color: #333333;
            line-height: 22px;
          }
        }
        .check-num-box {
          .item {
            display: inline-block;
            margin: 0 40px 0 0;
            .label {
              font-size: 12px;
              font-weight: 400;
              color: #aaaaaa;
              line-height: 17px;
            }
            .val {
              font-size: 14px;
              font-weight: 700;
              color: #333333;
              line-height: 16px;
              margin: 0 0 2px;
            }
            .val-res {
              font-size: 14px;
              font-weight: 500;
              color: #3f9f11;
              line-height: 20px;
            }
          }
        }
      }
    }
  }
}
</style>
