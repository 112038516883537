<template>
  <div class="equipment-calender-wrapper">
    <div class="calender-title">
      <span :class="{ active: !hide }" class="show-calender" @click="showAll"
        ><i class="iconfont iconfont-zhankai2"></i
      ></span>
      <div class="title-date">
        <van-icon name="arrow-left" @click="changeDate('m', -1)" />{{
          year
        }}年{{ month }}月<van-icon name="arrow" @click="changeDate('m', 1)" />
      </div>
      <span class="today" @click="gotoToday()">今天</span>
    </div>
    <div
      class="calender-body"
      @touchstart.prevent="onTouchStart"
      @touchend.prevent="onTouchEnd"
    >
      <div class="calender-weeks">
        <span
          v-for="item in weeks"
          :key="item.week"
          class="calender-item week"
          >{{ item.week }}</span
        >
      </div>
      <span
        v-for="(item, index) in days"
        :key="index"
        class="calender-item day"
        :data="JSON.stringify(item)"
        :class="{
          active: curActive.date1 == item.date,
          hasdata: hasDataObj[item.date2]
        }"
      >
        <i v-if="item.day != -1">{{ item.day != -1 ? item.day : "" }}</i></span
      >
    </div>
  </div>
</template>

<script>
import { getEquipmentHasData } from "@/api/basics/equipment";
export default {
  props: {
    id: {
      type: String,
      default: ""
    }
  },
  data() {
    return {
      weeks: [
        {
          week: "日"
        },
        {
          week: "一"
        },
        {
          week: "二"
        },
        {
          week: "三"
        },
        {
          week: "四"
        },
        {
          week: "五"
        },
        {
          week: "六"
        }
      ],
      month: new Date().getMonth() + 1,
      year: new Date().getFullYear(),
      hide: true,
      curActive: "",
      hasDataObj: {}
    };
  },
  computed: {
    firstDay() {
      return this.getMonthFirstDay(this.year, this.month);
    },
    days() {
      const ds = this.getMonthDays(this.year, this.month);
      const dayArr = Array.from({ length: ds }, (item, i) => {
        const day = `00${i + 1}`.slice(-2);
        const month = `00${this.month}`.slice(-2);
        return {
          day: i + 1,
          date: `${this.year}${month}${day}`,
          date2: `${this.year}-${month}-${day}`,
          datetime: `${this.year}-${month}-${day} 00:00:00`,
          nextdatetime: `${this.year}-${month}-${day} 23:59:59`
        };
      });
      for (let i = 0; i < this.firstDay; i++) {
        dayArr.unshift({
          day: -1,
          hide: true,
          date: ""
        });
      }
      if (this.hide) {
        return dayArr.slice(0, 7);
      }
      return dayArr;
    }
  },
  created() {
    this.curActive = this.getDateStr(new Date());
    this.getEquipmentHasData(this.curActive.year, this.curActive.month);
  },
  methods: {
    getEquipmentHasData(year, month) {
      const m = `00${month}`.slice(-2);
      const firstDay = `${year}-${m}-01`;
      const monthDays = this.getMonthDays(year, month);
      const lastDay = `${year}-${m}-${monthDays}`;
      getEquipmentHasData(this.id, {
        startDate: firstDay,
        endDate: lastDay
      }).then(res => {
        console.log("res", res);
        // 重新计算days
        this.hasDataObj = res;
      });
    },
    // 回到今天
    gotoToday() {
      // 设置年月
      const d = new Date();
      this.year = d.getFullYear();
      this.month = d.getMonth() + 1;
      // 设置当前激活的日期
      this.curActive = this.getDateStr(d);
    },
    // 选择日期
    onSelectDate(e) {
      const dom = e.target;
      const dataStr = dom.parentNode.getAttribute("data");
      if (!dataStr) {
        return;
      }
      const data = JSON.parse(dataStr);
      // 设置选中的日期
      this.curActive = {
        date1: data.date,
        date2: data.date2
      };
      this.$emit("change", data);
    },
    getDateStr(date) {
      const year = date.getFullYear();
      const m = date.getMonth() + 1;
      const month = `00${m}`.slice(-2);
      const day = `00${date.getDate()}`.slice(-2);
      return {
        date1: `${year}${month}${day}`,
        date2: `${year}-${month}-${day}`,
        year: year,
        month: m
      };
    },
    onTouchStart(e) {
      const startX = e.changedTouches[0].clientX;
      this.startX = startX;
      this.touchStartDate = Date.now();
    },
    onTouchEnd(e) {
      const endX = e.changedTouches[0].clientX;
      const touchEndDate = Date.now();
      // 滑动的距离
      const m = endX - this.startX;
      const t = touchEndDate - this.touchStartDate;
      if (t < 100 && m < 10) {
        this.onSelectDate(e);
        return;
      }
      // 速度
      const s = Math.abs(t / m);
      if (s > 0.5 && s < 1.8 && Math.abs(m) > 100) {
        // 向右滑动
        if (endX > this.startX) {
          this.changeDate("m", -1);
        } else {
          // 左滑
          this.changeDate("m", 1);
        }
      }
    },
    showAll() {
      this.hide = !this.hide;
    },
    // #region 日期变化
    changeDate(type, diff) {
      if (type == "y") {
        this.year = this.year + diff;
      } else if (type == "m") {
        const month = this.month + diff;
        if (diff == 1) {
          if (month > 12) {
            this.year++;
            this.month = 1;
          } else {
            this.month = month;
          }
        } else {
          if (month < 1) {
            this.year--;
            this.month = 12;
          } else {
            this.month = month;
          }
        }
      }
      // 切换年月需要重新调用获取是否有数据的接口
      this.getEquipmentHasData(this.year, this.month);
    },
    // #endregion
    // #region 获取第一天是周几0-6
    getMonthFirstDay(year, month) {
      const d = new Date(year, month - 1, 1);
      return d.getDay();
    },
    // #endregion
    // #region 获取某个月的天数
    getMonthDays(year, month) {
      const d = new Date(year, month, 0);
      return d.getDate();
    }
    // #endregion
  }
};
</script>

<style lang="scss" scoped>
.equipment-calender-wrapper {
  .calender-title {
    display: flex;
    justify-content: space-between;
    height: 30px;
    align-items: center;
    padding: 0 10px;
    .show-calender {
      text-align: center;
      width: 20px;
      height: 20px;
      line-height: 20px;
      display: inline-block;
      border-radius: 2px;
      i {
        position: relative;
        font-size: 14px;
      }
      &.active {
        background-color: #1676ff;
        i {
          color: #fff;
        }
      }
    }
    .title-date {
      .van-icon {
        vertical-align: top;
        padding: 0 8px;
      }
    }
    .today {
      color: #1676ff;
      font-size: 14px;
    }
  }
  .calender-body {
    .calender-weeks {
      border-bottom: 1px solid #e4e7ed;
    }
    .calender-item {
      display: inline-block;
      width: 14.28%;
      text-align: center;
      vertical-align: top;
      font-size: 12px;
      color: #646b74;
      font-weight: 500;
      &.day {
        padding: 4px;
        box-sizing: border-box;
      }
      i {
        display: inline-block;
        width: 28px;
        height: 28px;
        line-height: 28px;
      }
      &.week {
        color: #aaaaaa;
        height: 28px;
        line-height: 28px;
      }
      &.active {
        i {
          background-color: #1676ff;
          color: #fff;
          border-radius: 50%;
        }
      }
      &.hasdata {
        position: relative;
        &::before {
          position: absolute;
          content: "";
          bottom: 6px;
          left: 50%;
          transform: translate3d(-50%, 0, 0);
          width: 4px;
          height: 4px;
          border-radius: 2px;
          background-color: #ee3424;
        }
      }
    }
  }
}
</style>
